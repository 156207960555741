import { Component, OnInit } from '@angular/core';
import { ProductOptionService } from 'src/app/core/services';

@Component({
  selector: 'app-colour-selector',
  templateUrl: './colour-selector.component.html',
  styleUrls: ['./colour-selector.component.css']
})
export class ColourSelectorComponent implements OnInit {
  options: any;
  colours: string[] = [];
  codes: colourCode[] = [];
  selectedColour: colourCode;
  style = {};

  constructor(
    private productOptionService: ProductOptionService
  ) { }

  ngOnInit(): void {
    this.checkColours();
    this.options = this.productOptionService.get();
    this.colours = this.options.colours.filter(c => c && c !== '');
    if (this.colours.length > 0) {
      for (let i = 0; i < this.colours.length; i++) {
        let code = this.getColourCode(this.colours[i]);
        if (code) this.codes.push(code);
      }
      this.codes.sort((a, b) => a.sortOrder - b.sortOrder);
      if (this.codes.length > 0)
        this.selectColour(this.codes[0]);
    }

    let maxPerRow = 22;
    let itemColourWidthPx = 27;
    if (this.colours.length > maxPerRow) {
      let rows = Math.ceil(this.colours.length / maxPerRow);
      let itemsPerRow = Math.floor(this.colours.length / rows);
      this.style['max-width'] = `${itemsPerRow * itemColourWidthPx}px`;
    }
  }

  selectColour(colour: colourCode) {
    this.selectedColour = colour;
    this.productOptionService.selectColour(colour.code);
  }

  private getColourCode(colour: string): colourCode {
    let code: colourCode = this.colourCodes[colour.toLowerCase().trim()];
    if (code) {
      return code;
    }
    else {
      console.error('Colour code not found for : ' + colour)
    }
  }

  private checkColours() {
    for (let key in this.colourCodes) {
      if (this.colourCodes[key].primary.length !== 7)
        console.warn(`Colour ${key} has incorrect primary colour: ${this.colourCodes[key].primary}`)
      if (this.colourCodes[key].secondary && this.colourCodes[key].secondary.length !== 7)
        console.warn(`Colour ${key} has incorrect secondary colour: ${this.colourCodes[key].secondary}`)
    }
  }

  private colourCodes = {
    'wht/mrl': { 'code': 'wht/mrl', 'name': 'White/Marle', 'sortOrder': 0, 'primary': '#fffcf0' },
    'drkblu': { 'code': 'drkblu', 'name': 'Dark Blue', 'sortOrder': 0, 'primary': '#00008B' },
    'nat': { 'code': 'nat', 'name': 'Natural', 'sortOrder': 0, 'primary': '#E5D3BF' },
    'white': { 'code': 'white', 'name': 'White', 'sortOrder': 0, 'primary': '#ffffff' },
    'wht': { 'code': 'wht', 'name': 'White', 'sortOrder': 0, 'primary': '#ffffff' },
    'alu/blk': { 'code': 'alu/blk', 'name': 'ALU/BLK', 'sortOrder': 0, 'primary': '#d0d5db', 'secondary': '#000000' },
    'black': { 'code': 'black', 'name': 'Black', 'sortOrder': 0, 'primary': '#000000' },
    'blk': { 'code': 'blk', 'name': 'Black', 'sortOrder': 0, 'primary': '#000000' },
    'blue': { 'code': 'blue', 'name': 'Blue', 'sortOrder': 0, 'primary': '#ADD8E6' },
    'blk/red': { 'code': 'blk/red', 'name': 'BLK/RED', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#ff0000' },
    'blk/aqu': { 'code': 'blk/aqu', 'name': 'BLK/AQU', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#00FFFF' },
    'blk/gld': { 'code': 'blk/gld', 'name': 'Black/Gold', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#e6a21a' },
    'blk/org': { 'code': 'blk/org', 'name': 'Black/Orange', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#df7e37' },
    'blush': { 'code': 'blush', 'name': 'BLUSH', 'sortOrder': 0, 'primary': '#E8C7C8' },
    'bur': { 'code': 'bur', 'name': 'BUR', 'sortOrder': 0, 'primary': '#800020' },
    'org/blk': { 'code': 'org/blk', 'name': 'Orange/Black', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#df7e37' },
    'blk/wht': { 'code': 'blk/wht', 'name': 'Black/White', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#ffffff' },
    'cha': { 'code': 'cha', 'name': 'CHA', 'sortOrder': 0, 'primary': '#494649' },
    'cha/blk': { 'code': 'cha/blk', 'name': 'CHA/BLK', 'sortOrder': 0, 'primary': '#494649', 'secondary': '#000000' },
    'char': { 'code': 'char', 'name': 'Charcoal', 'sortOrder': 0, 'primary': '#494649' },
    'gph/blk': { 'code': 'gph/blk', 'name': 'GPH/BLK', 'sortOrder': 0, 'primary': '#251607', 'secondary': '#000000' },
    'grey': { 'code': 'grey', 'name': 'GREY', 'sortOrder': 0, 'primary': '#bab5b7' },
    'gry': { 'code': 'gry', 'name': 'GRY', 'sortOrder': 0, 'primary': '#bab5b7' },
    'gry/blk': { 'code': 'gry/blk', 'name': 'GRY/BLK', 'sortOrder': 0, 'primary': '#bab5b7', 'secondary': '#000000' },
    'gry/org': { 'code': 'gry/org', 'name': 'GRY/ORG', 'sortOrder': 0, 'primary': '#bab5b7', 'secondary': '#df7e37' },
    'jade': { 'code': 'jade', 'name': 'JADE', 'sortOrder': 0, 'primary': '#00a86b' },
    'lme': { 'code': 'lme', 'name': 'LME', 'sortOrder': 0, 'primary': '#dffc01' },
    'lme/blk': { 'code': 'lme/blk', 'name': 'LME/BLK', 'sortOrder': 0, 'primary': '#dffc01', 'secondary': '#000000' },
    'lme/nvy': { 'code': 'lme/nvy', 'name': 'LME/NVY', 'sortOrder': 0, 'primary': '#dffc01', 'secondary': '#262941' },
    'mar': { 'code': 'mar', 'name': 'MAR', 'sortOrder': 0, 'primary': '#800000' },
    'navy': { 'code': 'navy', 'name': 'NAVY', 'sortOrder': 0, 'primary': '#000080' },
    'nvy': { 'code': 'nvy', 'name': 'NVY', 'sortOrder': 0, 'primary': '#000080' },
    'org': { 'code': 'org', 'name': 'ORG', 'sortOrder': 0, 'primary': '#df7e37' },
    'org/nvy': { 'code': 'org/nvy', 'name': 'ORG/NVY', 'sortOrder': 0, 'primary': '#df7e37', 'secondary': '#000080' },
    'purple': { 'code': 'purple', 'name': 'Purple', 'sortOrder': 0, 'primary': '#800080' },
    'red': { 'code': 'red', 'name': 'Red', 'sortOrder': 0, 'primary': '#ff0000' },
    'roy': { 'code': 'roy', 'name': 'ROY', 'sortOrder': 0, 'primary': '#041690' },
    'sil': { 'code': 'sil', 'name': 'SIL', 'sortOrder': 0, 'primary': '#d1d0db' },
    'slate': { 'code': 'slate', 'name': 'SLATE', 'sortOrder': 0, 'primary': '#555555' },
    'slt': { 'code': 'slt', 'name': 'SLT', 'sortOrder': 0, 'primary': '#555555' },
    'stn': { 'code': 'stn', 'name': 'STN', 'sortOrder': 0, 'primary': '#DOB092' },
    'yel': { 'code': 'yel', 'name': 'YEL', 'sortOrder': 0, 'primary': '#e1f326' },
    'yel/cha': { 'code': 'yel/cha', 'name': 'YEL/CHA', 'sortOrder': 0, 'primary': '#e1f326', 'secondary': '#494649' },
    'yel/nvy': { 'code': 'yel/nvy', 'name': 'YEL/NVY', 'sortOrder': 0, 'primary': '#e1f326', 'secondary': '#262941' },
    'blk/sil': { 'code': 'blk/sil', 'name': 'Black/Silver', 'sortOrder': 0, 'primary': '#000000', 'secondary': '#d1d0db' },
    'arctic white satin': { 'code': 'arctic white satin', 'name': 'Arctic White Satin', 'sortOrder': 1, 'primary': '#cfd9e0' },
    'appliance white matt': { 'code': 'appliance white matt', 'name': 'Appliance White Matt', 'sortOrder': 2, 'primary': '#c1b8a9' },
    'pure white gloss': { 'code': 'pure white gloss', 'name': 'Pure White Gloss', 'sortOrder': 3, 'primary': '#d5d6c7' },
    'warm white pearl matt kinetic': { 'code': 'warm white pearl matt kinetic', 'name': 'Warm White Pearl Matt Kinetic', 'sortOrder': 4, 'primary': '#e3dcc0' },
    'off white matt': { 'code': 'off white matt', 'name': 'Off White Matt', 'sortOrder': 5, 'primary': '#d8c6a7' },
    'titania matt': { 'code': 'titania matt', 'name': 'Titania Matt', 'sortOrder': 6, 'primary': '#ccc3b4' },
    'canvas cloth matt': { 'code': 'canvas cloth matt', 'name': 'Canvas Cloth Matt', 'sortOrder': 7, 'primary': '#ad9b83' },
    'desert sand matt': { 'code': 'desert sand matt', 'name': 'Desert Sand Matt', 'sortOrder': 8, 'primary': '#897051' },
    'bronco matt': { 'code': 'bronco matt', 'name': 'Bronco Matt', 'sortOrder': 9, 'primary': '#766f5c' },
    'lignite matt': { 'code': 'lignite matt', 'name': 'Lignite Matt', 'sortOrder': 10, 'primary': '#1e1a17' },
    'window bronze kinetic': { 'code': 'window bronze kinetic', 'name': 'Window Bronze Kinetic', 'sortOrder': 11, 'primary': '#2b2321' },
    'anotec mid bronze': { 'code': 'anotec mid bronze', 'name': 'Anotec Mid Bronze', 'sortOrder': 12, 'primary': '#15100c' },
    'scoria matt': { 'code': 'scoria matt', 'name': 'Scoria Matt', 'sortOrder': 13, 'primary': '#361916' },
    'lichen matt': { 'code': 'lichen matt', 'name': 'Lichen Matt', 'sortOrder': 14, 'primary': '#464232' },
    'bond rivergum matt': { 'code': 'bond rivergum matt', 'name': 'Bond Rivergum Matt', 'sortOrder': 15, 'primary': '#d5bfa8' },
    'permanent green matt': { 'code': 'permanent green matt', 'name': 'Permanent Green Matt', 'sortOrder': 16, 'primary': '#040f0b' },
    'karaka matt': { 'code': 'karaka matt', 'name': 'Karaka Matt', 'sortOrder': 17, 'primary': '#070707' },
    'new denim blue matt': { 'code': 'new denim blue matt', 'name': 'New Denim Blue Matt', 'sortOrder': 18, 'primary': '#232623' },
    'slate blue matt': { 'code': 'slate blue matt', 'name': 'Slate Blue Matt', 'sortOrder': 19, 'primary': '#d8c7b2' },
    'gull grey matt': { 'code': 'gull grey matt', 'name': 'Gull Grey Matt', 'sortOrder': 20, 'primary': '#a18a70' },
    'ghost grey matt': { 'code': 'ghost grey matt', 'name': 'Ghost Grey Matt', 'sortOrder': 21, 'primary': '#787974' },
    'metro silver pearl kinetic': { 'code': 'metro silver pearl kinetic', 'name': 'Metro Silver Pearl Kinetic', 'sortOrder': 22, 'primary': '#82837e' },
    'silver star kinetic': { 'code': 'silver star kinetic', 'name': 'Silver Star Kinetic', 'sortOrder': 23, 'primary': '#d5c0a9' },
    'sandstone grey matt': { 'code': 'sandstone grey matt', 'name': 'Sandstone Grey Matt', 'sortOrder': 24, 'primary': '#d9ceb3' },
    'gunmetal metallic kinetic matt': { 'code': 'gunmetal metallic kinetic matt', 'name': 'Gunmetal Metallic Kinetic Matt', 'sortOrder': 25, 'primary': '#1b1b1b' },
    'thunder grey matt': { 'code': 'thunder grey matt', 'name': 'Thunder Grey Matt', 'sortOrder': 26, 'primary': '#191d1d' },
    'grey friars matt': { 'code': 'grey friars matt', 'name': 'Grey Friars Matt', 'sortOrder': 27, 'primary': '#252425' },
    'charcoal matt': { 'code': 'charcoal matt', 'name': 'Charcoal Matt', 'sortOrder': 28, 'primary': '#0e0c0e' },
    'windsor grey matt': { 'code': 'windsor grey matt', 'name': 'Windsor Grey Matt', 'sortOrder': 29, 'primary': '#242424' },
    'gravel matt': { 'code': 'gravel matt', 'name': 'Gravel Matt', 'sortOrder': 30, 'primary': '#181c1f' },
    'flax pod matt': { 'code': 'flax pod matt', 'name': 'Flax Pod Matt', 'sortOrder': 31, 'primary': '#101011' },
    'metro electric cow matt kinetic': { 'code': 'metro electric cow matt kinetic', 'name': 'Metro Electric Cow Matt Kinetic', 'sortOrder': 32, 'primary': '#1c2228' },
    'ironsand matt': { 'code': 'ironsand matt', 'name': 'Ironsand Matt', 'sortOrder': 33, 'primary': '#0f1014' },
    'metro coal dust kinetic': { 'code': 'metro coal dust kinetic', 'name': 'Metro Coal Dust Kinetic', 'sortOrder': 34, 'primary': '#060608' },
    'black matt': { 'code': 'black matt', 'name': 'Black Matt', 'sortOrder': 35, 'primary': '#090c0c' },
    'protexture black flat': { 'code': 'protexture black flat', 'name': 'Protexture Black Flat', 'sortOrder': 36, 'primary': '#363636' },
    'intensity moonlight satin': { 'code': 'intensity moonlight satin', 'name': 'Intensity Moonlight Satin', 'sortOrder': 37, 'primary': '#dabd1d' },
    'intensity sunshine gloss': { 'code': 'intensity sunshine gloss', 'name': 'Intensity Sunshine Gloss', 'sortOrder': 38, 'primary': '#d89a0b' },
    'intensity cc tomcat gloss': { 'code': 'intensity cc tomcat gloss', 'name': 'Intensity CC Tomcat Gloss', 'sortOrder': 39, 'primary': '#a2311b' },
    'intensity flame gloss': { 'code': 'intensity flame gloss', 'name': 'Intensity Flame Gloss', 'sortOrder': 40, 'primary': '#8b0817' },
    'intensity leaf satin': { 'code': 'intensity leaf satin', 'name': 'Intensity Leaf Satin', 'sortOrder': 41, 'primary': '#2d602c' },
    'intensity reef gloss': { 'code': 'intensity reef gloss', 'name': 'Intensity Reef Gloss', 'sortOrder': 42, 'primary': '#2b52a1' },
    'silver': { 'code': 'silver', 'name': 'Silver', 'sortOrder': 43, 'primary': '#aaa9ad' },
    'green': { 'code': 'green', 'name': 'Green', 'sortOrder': 44, 'primary': '#008000' },
    'pink': { 'code': 'pink', 'name': 'Pink', 'sortOrder': 46, 'primary': '#FFC0CB' },
    'space grey': { 'code': 'space grey', 'name': 'Space Grey', 'sortOrder': 47, 'primary': '#5d6475' },
    'starlight': { 'code': 'starlight', 'name': 'Starlight', 'sortOrder': 48, 'primary': '#f4edc6' },
    'midnight': { 'code': 'midnight', 'name': 'Midnight', 'sortOrder': 49, 'primary': '#191970' },
    'grey chateau': { 'code': 'grey chateau', 'name': 'Grey Chateau', 'sortOrder': 50, 'primary': '#929AA2' },
    'blue night': { 'code': 'blue night', 'name': 'Blue Night', 'sortOrder': 51, 'primary': '#191970' },
    'hurricane': { 'code': 'hurricane', 'name': 'Hurricane', 'sortOrder': 52, 'primary': '#8b7e77' },
    'steel grey': { 'code': 'steel grey', 'name': 'Steel Grey', 'sortOrder': 53, 'primary': '#71797E' },
    'casper': { 'code': 'casper', 'name': 'Casper', 'sortOrder': 54, 'primary': '#AAB5B8' },
    'ash': { 'code': 'ash', 'name': 'Ash', 'sortOrder': 56, 'primary': '#B2BEB5' },
    'granite': { 'code': 'granite', 'name': 'Granite', 'sortOrder': 57, 'primary': '#676767' },
    'dove': { 'code': 'dove', 'name': 'Dove', 'sortOrder': 58, 'primary': '#D3D3D3' },
    'snow': { 'code': 'snow', 'name': 'Snow', 'sortOrder': 59, 'primary': '#ffffff' },
    'sofia': { 'code': 'sofia', 'name': 'Sofia', 'sortOrder': 60, 'primary': '#8b7e77' },
    'cloud grey': { 'code': 'cloud grey', 'name': 'Cloud Grey', 'sortOrder': 61, 'primary': '#B8BEC3' },
    'graphite': { 'code': 'graphite', 'name': 'Graphite', 'sortOrder': 62, 'primary': '#4B4E53' },
    'royal navy': { 'code': 'royal navy', 'name': 'Royal Navy', 'sortOrder': 63, 'primary': '#191970' },
    'chocolate': { 'code': 'chocolate', 'name': 'Chocolate', 'sortOrder': 64, 'primary': '#7B3F00' },
    'brown': { 'code': 'brown', 'name': 'Brown', 'sortOrder': 65, 'primary': '#7B3F00' },
    'orange': { 'code': 'orange', 'name': 'Orange', 'sortOrder': 67, 'primary': '#FFA500' },
    'khaki': { 'code': 'khaki', 'name': 'Khaki', 'sortOrder': 68, 'primary': '#c3b091' },
    'pistachio': { 'code': 'pistachio', 'name': 'Pistachio', 'sortOrder': 69, 'primary': '#A9D39E' },
    'storm': { 'code': 'storm', 'name': 'Storm', 'sortOrder': 70, 'primary': '#708187' },
    'grey marle': { 'code': 'grey marle', 'name': 'Grey Marle', 'sortOrder': 71, 'primary': '#b7b2af' },
    'white marle': { 'code': 'white marle', 'name': 'White Marle', 'sortOrder': 72, 'primary': '#fffcf0' },
    'coal': { 'code': 'coal', 'name': 'Coal', 'sortOrder': 73, 'primary': '#4e4e4c' },
    'charcoal': { 'code': 'charcoal', 'name': 'Charcoal', 'sortOrder': 74, 'primary': '#4e4e4c' },
    'camel': { 'code': 'camel', 'name': 'Camel', 'sortOrder': 75, 'primary': '#C19A6B' },
    'army': { 'code': 'army', 'name': 'Army', 'sortOrder': 76, 'primary': '#4b5320' },
    'light blue': { 'code': 'light blue', 'name': 'Light Blue', 'sortOrder': 77, 'primary': '#ADD8E6' },
    'denim': { 'code': 'denim', 'name': 'Denim', 'sortOrder': 78, 'primary': '#6F8FAF' },
    'walnut': { 'code': 'walnut', 'name': 'Walnut', 'sortOrder': 79, 'primary': '#B28965' },
    'taupe': { 'code': 'taupe', 'name': 'Taupe', 'sortOrder': 80, 'primary': '#baa68e' },
    'lavender': { 'code': 'lavender', 'name': 'Lavender', 'sortOrder': 81, 'primary': '#dcd0ff' },
    'cream': { 'code': 'cream', 'name': 'Cream', 'sortOrder': 82, 'primary': '#FFFDD1' },
    'ruby red': { 'code': 'ruby red', 'name': 'Ruby Red', 'sortOrder': 83, 'primary': '#9b111e' },
    'ivory': { 'code': 'ivory', 'name': 'Ivory', 'sortOrder': 84, 'primary': '#FFFFF0' },
    'steel': { 'code': 'steel', 'name': 'Steel', 'sortOrder': 85, 'primary': '#71797E' },
    'butterscotch': { 'code': 'butterscotch', 'name': 'Butterscotch', 'sortOrder': 86, 'primary': '#D48827' },
    'fossil': { 'code': 'fossil', 'name': 'Fossil', 'sortOrder': 87, 'primary': '#bbb3ae' },
    'oyster': { 'code': 'oyster', 'name': 'Oyster', 'sortOrder': 88, 'primary': '#D7C9B1' },
    'charcoal tweed': { 'code': 'charcoal tweed', 'name': 'Charcoal Tweed', 'sortOrder': 89, 'primary': '#4e4e4c' },
    'dark green': { 'code': 'dark green', 'name': 'Dark Green', 'sortOrder': 90, 'primary': '#006400' },
    'indigo': { 'code': 'indigo', 'name': 'Indigo', 'sortOrder': 91, 'primary': '#4b0082' },
    'copper': { 'code': 'copper', 'name': 'Copper', 'sortOrder': 92, 'primary': '#D1793B' },
    'bronze': { 'code': 'bronze', 'name': 'Bronze', 'sortOrder': 93, 'primary': '#a97142' },
    'stainless steel': { 'code': 'stainless steel', 'name': 'Stainless Steel', 'sortOrder': 94, 'primary': '#CFD4D9' },
    'brushed stainless': { 'code': 'brushed stainless', 'name': 'Brushed Stainless', 'sortOrder': 95, 'primary': '#CFD4D9' },
    'blush green': { 'code': 'blush green', 'name': 'Blush Green', 'sortOrder': 96, 'primary': '#C3EFC3' },
    'blush purple': { 'code': 'blush purple', 'name': 'Blush Purple', 'sortOrder': 97, 'primary': '#dcd0ff' },
    'yellow': { 'code': 'yellow', 'name': 'Yellow', 'sortOrder': 98, 'primary': '#FFFF00' },
    'olive': { 'code': 'olive', 'name': 'Olive', 'sortOrder': 99, 'primary': '#808000' },
    'phantom black': { 'code': 'phantom black', 'name': 'Phantom Black', 'sortOrder': 100, 'primary': '#000000' },
    'pewter': { 'code': 'pewter', 'name': 'Pewter', 'sortOrder': 101, 'primary': '#8e9294' },
    'marigold': { 'code': 'marigold', 'name': 'Marigold', 'sortOrder': 102, 'primary': '#eba832' },
    'hazelnut': { 'code': 'hazelnut', 'name': 'Hazelnut', 'sortOrder': 103, 'primary': '#a8715a' },
    'blue/black': { 'code': 'blue/black', 'name': 'Blue/Black', 'sortOrder': 104, 'primary': '#191970' },
    'red/black': { 'code': 'red/black', 'name': 'Red/Black', 'sortOrder': 105, 'primary': '#ff0000' },
    'black/grey': { 'code': 'black/grey', 'name': 'Black/Grey', 'sortOrder': 106, 'primary': '#bab5b7' },
    'black/orange': { 'code': 'black/orange', 'name': 'Black/Orange', 'sortOrder': 107, 'primary': '#FFA500' },
    'turquoise': { 'code': 'turquoise', 'name': 'Turquoise', 'sortOrder': 108, 'primary': '#30D5C8' },
    'dark linen': { 'code': 'dark linen', 'name': 'Dark Linen', 'sortOrder': 109, 'primary': '#faf0e6' },
    'magenta': { 'code': 'magenta', 'name': 'Magenta', 'sortOrder': 110, 'primary': '#FF00FF' },
    'dune': { 'code': 'dune', 'name': 'Dune', 'sortOrder': 111, 'primary': '#c1bba3' },
    'moss': { 'code': 'moss', 'name': 'Moss', 'sortOrder': 112, 'primary': '#8A9A5B' },
    'gunmetal': { 'code': 'gunmetal', 'name': 'Gunmetal', 'sortOrder': 113, 'primary': '#8D918D' },
    'red/white': { 'code': 'red/white', 'name': 'Red/White', 'sortOrder': 114, 'primary': '#ff0000' },
    'race': { 'code': 'race', 'name': 'Race', 'sortOrder': 115, 'primary': '#000000' },
    'black/white': { 'code': 'black/white', 'name': 'Black/White', 'sortOrder': 116, 'primary': '#ffffff' },
    'stone blue': { 'code': 'stone blue', 'name': 'Stone Blue', 'sortOrder': 117, 'primary': '#809ca7' },
    'teal': { 'code': 'teal', 'name': 'Teal', 'sortOrder': 118, 'primary': '#008080' },
    'moonlight': { 'code': 'moonlight', 'name': 'Moonlight', 'sortOrder': 119, 'primary': '#506886' },
    'sage': { 'code': 'sage', 'name': 'Sage', 'sortOrder': 120, 'primary': '#9CAF88' },
    'tuscan orange': { 'code': 'tuscan orange', 'name': 'Tuscan Orange', 'sortOrder': 121, 'primary': '#FFA500' },
    'marine blue': { 'code': 'marine blue', 'name': 'Marine Blue', 'sortOrder': 122, 'primary': '#0981D1' },
    'sky': { 'code': 'sky', 'name': 'Sky', 'sortOrder': 123, 'primary': '#ADD8E6' },
    'sand': { 'code': 'sand', 'name': 'Sand', 'sortOrder': 124, 'primary': '#C2B280' },
    'mineral': { 'code': 'mineral', 'name': 'Mineral', 'sortOrder': 125, 'primary': '#CBFADC' },
    'waves': { 'code': 'waves', 'name': 'Waves', 'sortOrder': 126, 'primary': '#2E88C7' },
    'jokey red': { 'code': 'jokey red', 'name': 'Jokey Red', 'sortOrder': 127, 'primary': '#ff0000' },
    'smoke': { 'code': 'smoke', 'name': 'Smoke', 'sortOrder': 128, 'primary': '#676767' },
    'emerald': {'code': 'emerald', 'name': 'Emerald', 'sortOrder': 129, 'primary': '#50c878' },
    'emld': {'code': 'emld', 'name': 'EMLD', 'sortOrder': 130, 'primary': '#50c878' },
  }
}
interface colourCode {
  code: string,
  name: string,
  sortOrder: number,
  primary: string,
  secondary: string
}
